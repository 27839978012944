import axios from "axios";
import Swal from "sweetalert2";

const API_BASE_URL = "/mobilitys";
const MESSAGES_SUCCES = {
  creerUtilisateur: "تم تسجيل طلبك بنجاح",
  miseAJour: "تم التحديث",
};

export default {
  namespaced: true,
  state: {
    data: [],
    dataCollection: [],
    dataAuth: [],
    dataOne: {},
    currentPage: 1,
    totalPages: 0,
    isLoading: false,
  },
  mutations: {
    setCurrentPage(state, currentPage) {
      state.currentPage = currentPage;
    },
    setTotalPages(state, totalPages) {
      state.totalPages = totalPages;
    },
    setData(state, data) {
      state.data = data;
    },
    setDataAuth(state, dataAuth) {
      state.dataAuth = dataAuth;
    },
    setOneData(state, dataOne) {
      state.dataOne = dataOne;
    },
    setLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setDataCollection(state, dataCollection) {
      state.dataCollection = dataCollection;
    },
  },
  getters: {
    getData: (state) => state.data,
    getDataCollection: (state) => state.dataCollection,
    getDataAuth: (state) => state.dataAuth,
    getCurrentPage: (state) => state.currentPage,
    getTotalPages: (state) => state.totalPages,
    isLoading: (state) => state.isLoading,
  },
  actions: {
    async fetch({ commit, state }, params) {
      commit("setLoading", true);
      try {
        const response = await axios.get(
          `${API_BASE_URL}?page=${state.currentPage}&search=${params || ""}`
        );
        commit("setData", response.data);
        if (response.data.meta && response.data.meta.last_page) {
          commit("setTotalPages", response.data.meta.last_page);
        }
        return { success: true, response };
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        commit("setLoading", false); // Fin du chargement
      }
    },
    async fetchCollection({ commit }) {
      commit("setLoading", true);
      try {
        const response = await axios.get(`${API_BASE_URL}/collection`);
        commit("setDataCollection", response.data);
        return { success: true, response };
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        commit("setLoading", false); // Fin du chargement
      }
    },
    async fetchAll({ commit }) {
      commit("setLoading", true);
      try {
        const response = await axios.get(`${API_BASE_URL}/all`);
        commit("setData", response.data);
        return { success: true, response };
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        commit("setLoading", false); // Fin du chargement
      }
    },
    async fetchAuth({ commit, state }, params) {
      commit("setLoading", true);
      try {
        const response = await axios.get(`${API_BASE_URL}/mobilite_auth?page=${state.currentPage}&search=${params || ""}`);
        commit("setDataAuth", response.data);
        if (response.data && response.data.last_page) {
          commit("setTotalPages", response.data.last_page);
        }
        return { success: true, response };
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        commit("setLoading", false); // Fin du chargement
      }
    },
    async fetchOne({ commit }, params) {
      commit("setLoading", true);
      try {
        const response = await axios.get(`${API_BASE_URL}/${params}`);
        const item = response.data.data;
        commit("setOneData", item);
        return { success: true, response };
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        commit("setLoading", false); // Fin du chargement
      }
    },
    changePage({ commit, dispatch }, newPage) {
      commit("setCurrentPage", newPage);
      dispatch("fetch");
    },
    changePageAuth({ commit, dispatch }, newPage) {
      commit("setCurrentPage", newPage);
      dispatch("fetchAuth");
    },
    async store({ commit, dispatch }, params) {
      try {
        const response = await axios.post(API_BASE_URL, params);
        commit("setData", response.data);
        dispatch("showSuccessMessage", MESSAGES_SUCCES.creerUtilisateur);
        return { success: true, response };
      } catch (error) {
        // Handle registration error
        if (
          error.response.status === 422 &&
          error.response.data.error === "Email already exists"
        ) {
          console.log(
            "This email is already taken. Please choose another one."
          );
        } else {
          console.log("An error occurred during registration.");
        }
      }
    },
    showSuccessMessage(_, message) {
      console.log(message);
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    async update({ dispatch }, params) {
      try {
        console.log(params);
        const response = await axios.put(
          `${API_BASE_URL}/${params.id}`,
          params
        );
        dispatch("showSuccessMessage", MESSAGES_SUCCES.miseAJour);
        return { success: true, response };
      } catch (error) {
        console.error(error);
      }
    },
    async destroy(_, params) {
      try {
        const result = await Swal.fire({
          title: "هل أنت متأكد ؟",
          text: "لن تتمكن من العودة ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "إلغاء",
          confirmButtonText: "نعم، احذفه ",
        });
        if (result.isConfirmed) {
          await axios.delete(`${API_BASE_URL}/${params}`);
          Swal.fire("تم الحذف", "لقد تم حذف", "success");
        }
        return { success: true, result };
      } catch (error) {
        console.error(error);
      }
    },
  },
  modules: {},
};
