<template>
  <form @submit.prevent="submit">
    <div class="modal-header">
      <h4 class="modal-title">طلب تنظيم نشاط علمي</h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-hidden="true"
      >
        &times;
      </button>
    </div>
    <div class="modal-body policear">
      <div class="form-group">
        <label>العنوان</label>
        <input
          type="text"
          class="form-control"
          v-model="data.title"
          @blur="v$.data.title.$touch"
          :class="{ error: v$.data.title.$errors.length }"
        />
        <div
          class="input-errors"
          v-for="error of v$.data.title.$errors"
          :key="error.$uid"
        >
          <span class="error-msg">{{ error.$message }}</span>
        </div>
      </div>
      <div class="form-group">
        <label>تاريخ التنظيم</label>
        <input
          lang="en-us"
          type="date"
          class="form-control"
          v-model="data.date"
          @blur="v$.data.date.$touch"
          :class="{ error: v$.data.date.$errors.length }"
        />
        <div
          class="input-errors"
          v-for="error of v$.data.date.$errors"
          :key="error.$uid"
        >
          <span class="error-msg">{{ error.$message }}</span>
        </div>
        <div
                class="input-errors"
                style="text-align: left;text-align: right;"
                v-if="getErrorDate"
              >
                <span class="error-msg">{{ getErrorDate }}</span>
              </div>
      </div>
      <div class="form-group">
        <label>مدة النشاط</label>
        <input
          type="text"
          class="form-control"
          placeholder="عدد الساعات"
          v-model="data.duration"
        />
      </div>
      <div class="form-group">
        <label>القاعة / المدرج</label>
        <select
          class="form-select"
          v-model="data.place"
          @blur="v$.data.place.$touch"
          :class="{ error: v$.data.place.$errors.length }"
        >
          <!--<option value="" disabled selected>choisi...</option>-->
          <option value="amphie-fatima-mernissi">قاعة فاطمة المرنيسي</option>
          <option value="amphie-fatima-fihriya">مدرج فاطمة الفهرية</option>
          <option value="amphie-imame-malik">مدرج الإمام مالك</option>
          <option value="amphie-youssi">مدرج اليوسي</option>
          <option value="autre">آخر</option>
        </select>
        <div
          class="input-errors"
          v-for="error of v$.data.place.$errors"
          :key="error.$uid"
        >
          <span class="error-msg">{{ error.$message }}</span>
        </div>
      </div>
      <div class="form-group">
        <label>المنسق</label>
        <input
          type="text"
          class="form-control"
          v-model="data.coordinator"
          @blur="v$.data.coordinator.$touch"
          :class="{ error: v$.data.coordinator.$errors.length }"
        />
        <div
          class="input-errors"
          v-for="error of v$.data.coordinator.$errors"
          :key="error.$uid"
        >
          <span class="error-msg">{{ error.$message }}</span>
        </div>
      </div>
      <div class="form-group">
        <label>المختبر</label>
        <input type="text" class="form-control" v-model="data.laboratory" />
      </div>
      <div class="form-group">
        <label>الشعبة</label>
        <input type="text" class="form-control" v-model="data.department" />
      </div>
    </div>
    <div class="modal-footer">
      <input
        ref="removeButton"
        type="button"
        class="btn btn-default"
        style="background: beige !important"
        data-dismiss="modal"
        value="حذف"
      />
      <input
        type="submit"
        class="btn btn-success"
        :disabled="!isAllFieldsFilled"
        value="إرسال"
      />
    </div>
  </form>
</template>
<script>
import Swal from "sweetalert2";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapGetters, mapActions } from "vuex";
import { mapState } from 'vuex';
const Path = "Event/";
const PathConstrained = "Constrained/";
export default {
  data() {
    return {
      v$: useVuelidate(),
      isAllFieldsFilled: false,
      message: false,
      data: {
        id: "",
        title: "",
        duration: "",
        place: "",
        coordinator: "",
        laboratory: "",
        department: "",
        date: "",
        type: "scientifique",
        status: "encours",
      },
    };
  },
  watch: {
    data: {
      handler: "checkAllFields",
      deep: true,
    },
  },
  methods: {
    checkAllFields() {
      this.isAllFieldsFilled =
        this.data.title &&
        this.data.place &&
        this.data.coordinator &&
        this.data.date;
      this.data.type;
    },
    ...mapActions({
      store: Path + "store",
      fetchOneConstrained: PathConstrained + "fetchOne",
      fetchAuth: Path + "fetchAuth",
    }),
    async submit() {
      const currentDate = new Date();
      const selectedDate = new Date(this.data.date);
      const timeDifference = selectedDate.getTime() - currentDate.getTime();

      if (timeDifference < this.getDays * 24 * 60 * 60 * 1000) {
        this.errorMessage = `يجب الإخبار بتنظيم النشاط ${this.getDays} يوما  على الأقل قبل تاريخ التنظيم`;
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: this.errorMessage,
        });
      } else {
        try {
          const result = await this.store(this.data);
          if (result.success) {
            this.$refs.removeButton.click();
            this.clearFormData();
            this.message = true;
            this.$emit("messageToParent", this.message);
            this.fetchAuth()
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    clearFormData() {
      this.data = {
        id: "",
        title: "",
        duration: "",
        place: "",
        coordinator: "",
        laboratory: "",
        department: "",
        date: "",
        type: "scientifique",
        status: "encours",
      };
      this.v$.$reset();
      this.getErrorDate = ''
    },
  },
  validations() {
    return {
      data: {
        title: { required },
        place: { required },
        coordinator: { required },
        date: { required },
        type: { required },
      },
    };
  },
  computed: {
    imagePath() {
      return this.$store.state.imagePath;
    },
    ...mapGetters({
      getDays: PathConstrained + "getDays",
      getErrorDate:  "Event/getErrorDate",
    }),
    ...mapState(['addActivite'])
  },
  mounted() {
    this.fetchOneConstrained();
  },
};
</script>
<style scoped>
.modal-content {
  direction: rtl;
}
.modal-header {
  direction: rtl;
}
.form-control {
  direction: rtl;
}
.form-group {
  text-align: right;
}
.form-select {
  direction: rtl;
}
</style>
