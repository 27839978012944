<template>
  <div dir="rtl" class="container-fluid table-crud">
    <div class="container-fluid">
      <div class="table-responsive">
        <div class="table-wrapper">
          <div class="table-title">
            <div class="row justify-content-between">
              <div class="col-sm-5 text-right policear">
                <h2>لائحة الأنشطة العلمية والثقافية <b></b></h2>
              </div>
              <div class="col-sm-3 policear">
                <input
                  v-model="search"
                  style="padding: 16px !important; font-size: x-small"
                  type="search"
                  class="form-control border-0 bg-white"
                  id="search-input"
                  placeholder="البحث"
                />
              </div>
            </div>
          </div>
          <table v-if="getDataAuth.data?.length > 0" class="table table-striped table-hover">
            <thead>
              <tr class="policear">
                <th class="text-right">العنوان</th>
                <th class="text-right">تاريخ التنظيم</th>
                <th class="text-right">مدة النشاط</th>
                <th class="text-right">القاعة / المدرج</th>
                <th class="text-right">المنسق</th>
                <th class="text-right">المختبر</th>
                <th class="text-right">الشعبة</th>
                <th class="text-right">نوع النشاط</th>
                <th class="text-right">الحالة</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in getDataAuth.data" :key="item.id">
                <td class="text-right">{{ item.title }}</td>
                <td class="text-right">{{ item.date?.slice(0, 10) }}</td>
                <td class="text-right">{{ item.duration }} س</td>
                <td class="text-right" v-if="item.place == 'amphie-fatima-mernissi'">قاعة فاطمة المرنيسي</td>
                      <td class="text-right" v-if="item.place == 'amphie-fatima-fihriya'">مدرج فاطمة الفهرية</td>
                      <td class="text-right" v-if="item.place == 'amphie-imame-malik'">مدرج الإمام مالك</td>
                      <td class="text-right" v-if="item.place == 'amphie-youssi'">مدرج اليوسي</td>
                      <td class="text-right" v-if="item.place == 'autre'">آخر</td>
                <td class="text-right">{{ item.coordinator }}</td>
                <td class="text-right">{{ item.laboratory === 'aucun' ? 'بدون' : item.laboratory }}</td>
                <td class="text-right">{{ item.department === 'aucun' ? 'بدون' : item.department }}</td>
                <td class="text-right" v-if="item.type == 'scientifique'">
                  علمي
                </td>
                <td class="text-right" v-if="item.type == 'culturelle'">
                  ثقافي
                </td>
                <td class="text-right" v-if="item.type == 'autre'">آخر</td>

                <td class="text-right" v-if="item.status == 'encours'">
                  <div class="encour">جاري</div>
                </td>
                <td class="text-right" v-if="item.status == 'valider'">
                  <div class="valider">مقبول</div>
                </td>
                <td class="text-right" v-if="item.status == 'novalider'">
                  <div class="novalider">مرفوض</div>
                </td>
              </tr>
            </tbody>
          </table>
          <div style="color: #dc3545;" class="policear text-center" v-else>لا يوجد اي طلب</div>
          <div class="clearfix mt-5 text-right">
            <PaginationComponent
              v-if="getTotalPages > 1"
              :current-page="getCurrentPage"
              :total-pages="getTotalPages"
              @page-changed="PageChange"
            />
          </div>
        </div>
      </div>



      <div v-if="getDataAuth.data?.length > 0" class="responsivec-card policear">
      <input
                  v-model="search"
                  style="padding: 16px !important; font-size: x-small"
                  type="search"
                  class="form-control bg-white mb-3"
                  id="search-input"
                  placeholder="البحث"
                />
        <div class="row">
          <div class="col-12" v-for="item in getDataAuth.data" :key="item.id">
            <div class="card">
  <div class="card-body">
    <h5 class="card-title">العنوان : {{ item.title }}</h5><br>
    <h6 class="card-subtitle mb-2">تاريخ التنظيم : {{ item.date?.slice(0, 10) }}</h6>
    <h6 class="card-subtitle mb-2">مدة النشاط : {{ item.duration }} س</h6>
    <h6 class="card-subtitle mb-2" v-if="item.type == 'scientifique'">نوع النشاط : علمي</h6>
    <h6 class="card-subtitle mb-2" v-if="item.type == 'culturelle'">نوع النشاط : ثقافي</h6>
    <h6 class="card-subtitle mb-2" v-if="item.type == 'autre'">نوع النشاط : آخر</h6>
    <h6 class="card-subtitle mb-2">القاعة / المدرج : 
        <span class="text-right" v-if="item.place == 'amphie-fatima-mernissi'">قاعة فاطمة المرنيسي</span>
        <span class="text-right" v-if="item.place == 'amphie-fatima-fihriya'">مدرج فاطمة الفهرية</span>
        <span class="text-right" v-if="item.place == 'amphie-imame-malik'">مدرج الإمام مالك</span>
        <span class="text-right" v-if="item.place == 'amphie-youssi'">مدرج اليوسي</span>
        <span class="text-right" v-if="item.place == 'autre'">آخر</span>
    </h6>
    <h6 class="card-subtitle mb-2">المنسق : {{ item.coordinator }}</h6>
    <h6 class="card-subtitle mb-2">المختبر : {{ item.laboratory === 'aucun' ? 'بدون' : item.laboratory }}</h6>
    <h6 class="card-subtitle mb-2">الشعبة : {{ item.department === 'aucun' ? 'بدون' : item.department }}</h6>
    <h6 class="card-subtitle mb-2" v-if="item.status == 'encours'">الحالة : <span class="encours">جاري</span></h6>
    <h6 class="card-subtitle mb-2" v-if="item.status == 'valider'">الحالة : <span class="valider">مقبول</span></h6>
    <h6 class="card-subtitle mb-2" v-if="item.status == 'novalider'">الحالة : <span class="novalider">مرفوض</span></h6>
  </div>
</div>
          </div>
        </div>
        <div class="clearfix mt-5 text-right">
            <PaginationComponent
            v-if="getTotalPages > 1"
              :current-page="getCurrentPage"
              :total-pages="getTotalPages"
              @page-changed="PageChange"
            />
          </div>
      </div>
      <div style="color: #dc3545;" class="policear responsivec-card text-center m-2" v-else>لا يوجد اي طلب</div>

      


    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
const Path = "Event/";
export default {
  data() {
    return {
      search: "",
    };
  },
  watch: {
    search: {
      handler(newSearch) {
        const searchTerm = newSearch || "";
        this.$store.dispatch(Path + "fetchAuth", searchTerm);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      signOut: "Auth/signOut",
      fetchAuth: Path + "fetchAuth",
      changePageAuth: Path + "changePageAuth",
    }),
    async PageChange(newPage) {
      try {
        const result = await this.changePageAuth(newPage);
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    ...mapGetters({
      getDataAuth: Path + "getDataAuth",
      getCurrentPage: Path + "getCurrentPage",
      getTotalPages: Path + "getTotalPages",
    }),
  },
  mounted() {
    this.fetchAuth();
  },
};
</script>

<style scoped>
.responsivec-card {
  display: none;
}
.table-responsive {
  display: block;
}
@media (min-width: 1200px) {
  /* Styles pour les écrans larges */
}
@media (min-width: 768px) and (max-width: 1199px) {
  /* Styles pour les tablettes */
}
@media (max-width: 932px) {
  .responsivec-card {
  display: block;
}
.table-responsive {
  display: none;
}
  .card .card-title {
    color: var(--white);
    font-size: 13px;
    padding-bottom: 13px;
    background: cadetblue;
    padding: 11px;
    border-radius: 5px;
    float: initial;
  }
  .card h6 {
    color: chocolate;
    font-size: 14px;
    margin-bottom: 15px !important;
}
.card .encours {
  background: #ffc107;
  color: white;
  padding: 5px 12px;
  border-radius: 5px;
}
.card .valider {
  background: var(--green);
  color: white;
  padding: 5px 12px;
  border-radius: 5px;
}
.card .novalider {
  background: #dc3545;
  color: white;
  padding: 5px 12px;
  border-radius: 5px;
}
}
</style>
