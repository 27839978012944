<template>
  <div class="wrapper">
    <NavComponent />
    <AsideComponent />
    <div class="content-wrapper" @click="handleOutsideClick">
      <div class="content policear">
        <div class="container-fluid table-crud">
          <div class="container-fluid">
            <div class="table-responsive">
              <div class="table-wrapper">
                <div class="table-title">
                  <div class="row">
                    <div class="col-sm-5 text-right">
                      <h2>لائحة<b></b></h2>
                    </div>
                    <div class="col-sm-3 input-search">
                      <input
                        v-model="search"
                        style="padding: 16px !important; font-size: x-small"
                        type="search"
                        class="form-control border-0 bg-white"
                        id="search-input"
                        placeholder="البحث"
                      />
                    </div>
                    <div class="col-sm-4">
                      <a
                        href="#addEmployeeModal"
                        class="btn btn-success"
                        data-toggle="modal"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-plus-square ml-1"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"
                          />
                          <path
                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"
                          />
                        </svg>
                        <span>إضافة</span></a
                      >
                      <download-excel :data="getDataCollection">
                        <a class="btn btn-warning" data-toggle="modal">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-file-earmark-spreadsheet ml-1"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5zM3 12v-2h2v2zm0 1h2v2H4a1 1 0 0 1-1-1zm3 2v-2h3v2zm4 0v-2h3v1a1 1 0 0 1-1 1zm3-3h-3v-2h3zm-7 0v-2h3v2z"
                            />
                          </svg>
                          <span>تحميل ايكسيل</span></a
                        >
                      </download-excel>
                    </div>
                  </div>
                </div>
                <table class="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th class="text-right">إسم المستفيد</th>
                      <th class="text-right">نسب المستفيد</th>
                      <th class="text-right">تاريخ الذهاب</th>
                      <th class="text-right">تاريخ العودة</th>
                      <th class="text-right">الوجهة</th>
                      <th class="text-right">المختبر</th>
                      <th class="text-right">الشعبة</th>
                      <th class="text-right">الحالة</th>
                      <th class="text-right">إجراءات</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in getData.data" :key="item.id">
                      <td class="text-right">{{ item.first_name_benefit }}</td>
                      <td class="text-right">{{ item.last_name_benefit }}</td>
                      <td class="text-right">
                        {{ item.date_go?.slice(0, 10) }}
                      </td>
                      <td class="text-right">
                        {{ item.date_return?.slice(0, 10) }}
                      </td>
                      <td class="text-right">{{ item.destination }}</td>
                      <td class="text-right">{{ item.laboratory === 'aucun' ? 'بدون' : item.laboratory }}</td>
                      <td class="text-right">{{ item.department === 'aucun' ? 'بدون' : item.department }}</td>
                      <td class="text-right" v-if="item.status == 'encours'">
                        <div class="encour">جاري</div>
                      </td>
                      <td class="text-right" v-if="item.status == 'valider'">
                        <div class="valider">مقبول</div>
                      </td>
                      <td class="text-right" v-if="item.status == 'novalider'">
                        <div class="novalider">مرفوض</div>
                      </td>
                      <td class="text-right">
                        <a
                          href="#editEmployeeModal"
                          @click="OneFetch(item.id)"
                          class="edit mr-1"
                          data-toggle="modal"
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
                            />
                            <path
                              fill-rule="evenodd"
                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                            /></svg
                        ></a>
                        <a
                          href="#deleteEmployeeModall"
                          @click="submitDestroy(item.id)"
                          class="delete"
                          data-toggle="modal"
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-trash"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"
                            />
                            <path
                              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"
                            /></svg
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="clearfix mt-5 text-right">
                  <PaginationComponent
                    :current-page="getCurrentPage"
                    :total-pages="getTotalPages"
                    @page-changed="PageChange"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterBackComponent />
  </div>

  <div id="addEmployeeModal" class="modal fade" ref="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="submit">
          <div class="modal-header" dir="rtl">
            <h4 class="modal-title policear text-right">إضافة</h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div class="modal-body policear">
            <div class="form-group">
              <label>إسم المستفيد</label>
              <input
                type="text"
                class="form-control"
                v-model="data.first_name_benefit"
                @blur="v$.data.first_name_benefit.$touch"
                :class="{ error: v$.data.first_name_benefit.$errors.length }"
              />
              <div
                class="input-errors"
                v-for="error of v$.data.first_name_benefit.$errors"
                :key="error.$uid"
              >
                <span class="error-msg">{{ error.$message }}</span>
              </div>
            </div>
            <div class="form-group">
              <label>نسب المستفيد</label>
              <input
                type="text"
                class="form-control"
                v-model="data.last_name_benefit"
                @blur="v$.data.last_name_benefit.$touch"
                :class="{ error: v$.data.last_name_benefit.$errors.length }"
              />
              <div
                class="input-errors"
                v-for="error of v$.data.last_name_benefit.$errors"
                :key="error.$uid"
              >
                <span class="error-msg">{{ error.$message }}</span>
              </div>
            </div>
            <div class="form-group">
              <label>تاريخ الذهاب </label>
              <input
                type="date"
                class="form-control"
                v-model="data.date_go"
                @blur="v$.data.date_go.$touch"
                :class="{ error: v$.data.date_go.$errors.length }"
              />
              <div
                class="input-errors"
                v-for="error of v$.data.date_go.$errors"
                :key="error.$uid"
              >
                <span class="error-msg">{{ error.$message }}</span>
              </div>
            </div>
            <div class="form-group">
              <label>تاريخ العودة </label>
              <input
                type="date"
                class="form-control"
                v-model="data.date_return"
                @blur="v$.data.date_return.$touch"
                :class="{ error: v$.data.date_return.$errors.length }"
              />
              <div
                class="input-errors"
                v-for="error of v$.data.date_return.$errors"
                :key="error.$uid"
              >
                <span class="error-msg">{{ error.$message }}</span>
              </div>
            </div>
            <div class="form-group">
              <label>الوجهة</label>
              <input
                type="text"
                class="form-control"
                v-model="data.destination"
                @blur="v$.data.destination.$touch"
                :class="{ error: v$.data.destination.$errors.length }"
              />
              <div
                class="input-errors"
                v-for="error of v$.data.destination.$errors"
                :key="error.$uid"
              >
                <span class="error-msg">{{ error.$message }}</span>
              </div>
            </div>

            <div class="form-group">
              <label>المختبر</label>
              <input
                type="text"
                class="form-control"
                v-model="data.laboratory"
              />
            </div>
            <div class="form-group">
              <label>الشعبة</label>
              <input
                type="text"
                class="form-control"
                v-model="data.department"
              />
            </div>
          </div>
          <div class="modal-footer">
            <input
              ref="removeButton"
              type="button"
              class="btn btn-default"
              style="background: beige !important"
              data-dismiss="modal"
              value="إلغاء"
            />
            <input
              type="submit"
              class="btn btn-success"
              :disabled="!isAllFieldsFilled"
              value="أضف"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
  <div id="editEmployeeModal" class="modal fade" ref="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="submitUpdate">
          <div class="modal-header" dir="rtl">
            <h4 class="modal-title policear text-right">إضافة</h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div class="modal-body policear">
            <div class="form-group">
              <label>إسم المستفيد</label>
              <input
                type="text"
                class="form-control"
                v-model="data.first_name_benefit"
                @blur="v$.data.first_name_benefit.$touch"
                :class="{ error: v$.data.first_name_benefit.$errors.length }"
              />
              <div
                class="input-errors"
                v-for="error of v$.data.first_name_benefit.$errors"
                :key="error.$uid"
              >
                <span class="error-msg">{{ error.$message }}</span>
              </div>
            </div>
            <div class="form-group">
              <label>نسب المستفيد</label>
              <input
                type="text"
                class="form-control"
                v-model="data.last_name_benefit"
                @blur="v$.data.last_name_benefit.$touch"
                :class="{ error: v$.data.last_name_benefit.$errors.length }"
              />
              <div
                class="input-errors"
                v-for="error of v$.data.last_name_benefit.$errors"
                :key="error.$uid"
              >
                <span class="error-msg">{{ error.$message }}</span>
              </div>
            </div>
            <div class="form-group">
              <label>تاريخ الذهاب </label>
              <input
                type="date"
                class="form-control"
                v-model="data.date_go"
                @blur="v$.data.date_go.$touch"
                :class="{ error: v$.data.date_go.$errors.length }"
              />
              <div
                class="input-errors"
                v-for="error of v$.data.date_go.$errors"
                :key="error.$uid"
              >
                <span class="error-msg">{{ error.$message }}</span>
              </div>
            </div>
            <div class="form-group">
              <label>تاريخ العودة </label>
              <input
                type="date"
                class="form-control"
                v-model="data.date_return"
                @blur="v$.data.date_return.$touch"
                :class="{ error: v$.data.date_return.$errors.length }"
              />
              <div
                class="input-errors"
                v-for="error of v$.data.date_return.$errors"
                :key="error.$uid"
              >
                <span class="error-msg">{{ error.$message }}</span>
              </div>
            </div>
            <div class="form-group">
              <label>الحالة</label>
              <select class="form-select" v-model="data.status">
                <option value="encours">جاري</option>
                <option value="valider">مقبول</option>
                <option value="novalider">مرفوض</option>
              </select>
            </div>
            <div class="form-group">
              <label>الوجهة</label>
              <input
                type="text"
                class="form-control"
                v-model="data.destination"
                @blur="v$.data.destination.$touch"
                :class="{ error: v$.data.destination.$errors.length }"
              />
              <div
                class="input-errors"
                v-for="error of v$.data.destination.$errors"
                :key="error.$uid"
              >
                <span class="error-msg">{{ error.$message }}</span>
              </div>
            </div>

            <div class="form-group">
              <label>المختبر</label>
              <input
                type="text"
                class="form-control"
                v-model="data.laboratory"
              />
            </div>
            <div class="form-group">
              <label>الشعبة</label>
              <input
                type="text"
                class="form-control"
                v-model="data.department"
              />
            </div>
          </div>
          <div class="modal-footer">
            <input
              ref="removeButtonUpdate"
              type="button"
              style="background: beige !important"
              class="btn btn-default"
              data-dismiss="modal"
              value="إلغاء"
            />
            <input type="submit" class="btn btn-success" value="تعديل" />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapGetters, mapActions } from "vuex";
const Path = "Mobilitie/";
const PathConstrained = "Constrained/";
export default {
  data() {
    return {
      search: "",
      v$: useVuelidate(),
      data: {
        id: "",
        first_name_benefit: "",
        last_name_benefit: "",
        date_go: "",
        date_return: "",
        status: "encours",
        destination: "",
        laboratory: "",
        department: "",
      },
      isAllFieldsFilled: false,
    };
  },
  validations() {
    return {
      data: {
        first_name_benefit: { required },
        last_name_benefit: { required },
        date_go: { required },
        date_return: { required },
        status: { required },
        destination: { required },
        laboratory: { required },
        department: { required },
      },
    };
  },
  computed: {
    ...mapGetters({
      getData: Path + "getData",
      getOneData: PathConstrained + "getOneData",
      getDataCollection: Path + "getDataCollection",
      getTowDays: PathConstrained + "getTowDays",
      getCurrentPage: Path + "getCurrentPage",
      getTotalPages: Path + "getTotalPages",
    }),
  },
  watch: {
    search: {
      handler(newSearch) {
        const searchTerm = newSearch || "";
        this.$store.dispatch(Path + "fetch", searchTerm);
      },
      immediate: true,
    },
    data: {
      handler: "checkAllFields",
      deep: true,
    },
  },
  methods: {
    formatPlace(str) {
      let words = str?.split("-");
      for (let i = 0; i < words?.length; i++) {
        words[i] = words[i]?.charAt(0).toUpperCase() + words[i]?.slice(1);
      }
      return words?.join(" ");
    },
    checkAllFields() {
      this.isAllFieldsFilled =
        this.data.first_name_benefit &&
        this.data.last_name_benefit &&
        this.data.date_go &&
        this.data.date_return &&
        this.data.destination;
    },
    ...mapActions({
      fetch: Path + "fetch",
      fetchOne: Path + "fetchOne",
      fetchCollection: Path + "fetchCollection",
      store: Path + "store",
      update: Path + "update",
      destroy: Path + "destroy",
      changePage: Path + "changePage",
      fetchOneConstrained: PathConstrained + "fetchOne",
      fetchTwoConstrained: PathConstrained + "fetchTwo",
    }),
    handleOutsideClick(event) {
      const modal = this.$refs.modal;
      if (modal && !modal.contains(event.target)) {
        this.clearFormData();
      }
    },
    async PageChange(newPage) {
      try {
        const result = await this.changePage(newPage);
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    },
    async OneFetch(id) {
      try {
        const result = await this.fetchOne(id);
        const getOneData = result.response.data.data;

        if (result.success) {
          this.data = { ...getOneData };
        }
      } catch (error) {
        console.log(error);
      }
    },
    async submit() {
      const currentDate = new Date();
      const selectedDate = new Date(this.data.date_go);
      const timeDifference = selectedDate.getTime() - currentDate.getTime();

      if (timeDifference < this.getTowDays * 24 * 60 * 60 * 1000) {
        this.errorMessage = `يجب الإخبار بموعد السفر ${this.getTowDays} يوما  على الأقل قبل تاريخ السفر`;
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: this.errorMessage,
        });
      } else {
        try {
          const result = await this.store(this.data);
          if (result.success) {
            this.fetch();
            this.$refs.removeButton.click();
            this.clearFormData();
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    async submitUpdate() {
      const currentDate = new Date();
      const selectedDate = new Date(this.data.date_go);
      const timeDifference = selectedDate.getTime() - currentDate.getTime();

      if (timeDifference < this.getTowDays * 24 * 60 * 60 * 1000) {
        this.errorMessage = `يجب الإخبار بموعد السفر ${this.getTowDays} يوما  على الأقل قبل تاريخ السفر`;
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: this.errorMessage,
        });
      } else {
        try {
          const result = await this.update(this.data);
          if (result.success) {
            this.fetch();
            this.$refs.removeButtonUpdate.click();
            this.clearFormData();
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    async submitDestroy(data) {
      try {
        const result = await this.destroy(data);
        if (result.success) {
          this.fetch();
        }
      } catch (error) {
        console.log(error);
      }
    },
    clearFormData() {
      this.data = {
        id: "",
        first_name_benefit: "",
        last_name_benefit: "",
        date_go: "",
        date_return: "",
        status: "encours",
        destination: "",
        laboratory: "",
        department: "",
      };
      this.v$.$reset();
    },
  },
  mounted() {
    this.fetch();
    this.fetchCollection();
    this.fetchOneConstrained();
    this.fetchTwoConstrained();
    document.body.classList.add("hold-transition", "sidebar-mini");
  },
  /*beforeRouteLeave(to, from, next) {
    console.log("beforeRouteLeave");
    document.body.classList.remove("hold-transition", "sidebar-mini");
    next();
  },*/
};
</script>
<style>
.modal .form-group {
  text-align: right;
}
.form-control,
.form-select {
  text-align: right;
}
@media (max-width: 932px) {
  .btn-success, .btn-warning {
      margin-top: 15px;
  }
  .input-search {
      margin-top: 20px;
  }
  .input-search .form-control {
    width: 250px;
  }
  }
</style>
