<template>
  <div class="wrapper">
    <NavComponent />
    <AsideComponent />
    <div class="content-wrapper">
      <div class="content">
        <div class="container-fluid table-crud">
          <div class="pt-5">
            <div class="row">
              <div class="col-md-4">
                <div class="color-intro mb-2 p-3 bg-primary policear text-center">
                قاعة فاطمة المرنيسي
                </div>
              </div>
              <div class="col-md-4">
                <div class="color-intro mb-2 p-3 bg-success policear text-center">مدرج فاطمة الفهرية
                </div>
              </div>
              <div class="col-md-4">
                <div class="color-intro mb-2 p-3 bg-danger policear text-center">مدرج الإمام مالك</div>
              </div>
              <div class="col-md-4">
                <div class="color-intro mb-2 p-3 bg-warning policear text-center">مدرج اليوسي</div>
              </div>
              <div class="col-md-4">
                <div class="color-intro mb-2 p-3 bg-info policear text-center">آخر</div>
              </div>
              <div class="col-md-4">
                <div class="color-intro mb-2 p-3 bg-secondary policear text-center">
                  حدث سابق
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-center">
    <v-calendar
      :masks="masks"
      :attributes="attrs"
      disable-page-swipe
      is-expanded
    >
      <template v-slot:day-content="{ day, attributes }">
        <div class="flex flex-col h-full z-10 overflow-hidden">
          <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
          <div class="flex-grow overflow-y-auto overflow-x-auto">
            <h6
              v-for="attr in attributes"
              :key="attr.key"
              @click="OneFetch(attr.customData.id)"
              class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1"
              :class="attr.customData.class"
              style="cursor: pointer;"
            >
              <span class="policear title-event">{{ attr.customData.title }}</span><br>
              <hr class="title-event" style="margin: 2px;">
              <span class="policear" v-if="attr.customData.place == 'amphie-imame-malik'">مدرج الإمام مالك</span>
              <span class="policear" v-if="attr.customData.place == 'amphie-fatima-mernissi'">قاعة فاطمة المرنيسي</span>
              <span class="policear" v-if="attr.customData.place == 'amphie-youssi'">مدرج اليوسي</span>
              <span class="policear" v-if="attr.customData.place == 'amphie-fatima-fihriya'">مدرج فاطمة الفهرية</span>
              <span class="policear" v-if="attr.customData.place == 'autre'">آخر</span>
            </h6>
          </div>
        </div>
      </template>
    </v-calendar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <FooterBackComponent />

    <!-- Button trigger modal -->
    <button
      ref="exampleModalButton"
      style="display: none"
      type="button"
      class="btn btn-primary"
      data-toggle="modal"
      data-target="#exampleModal"
    ></button>

    <!-- Modal -->
    <div class="modal modal-event fade bd-example-modal-lg" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content" dir="rtl">
      <div class="modal-header">
            <h5 class="modal-title policear" id="exampleModalLabel">
             العنوان :  {{ data.title }} 
            </h5>
          </div>
          <div class="modal-body policear">
            <h6 class="mb-3">
              <span style="font-weight: 600; font-size: 20px;color: var(--secondary);">تاريخ التنظيم : </span>
              <span style="font-size: 20px"> {{ data.date?.slice(0, 10) }}</span>
            </h6>
            <h6 class="mb-3"><span style="font-weight: 600; font-size: 20px;color: var(--secondary);">مدة النشاط : </span><span style="font-size: 20px">{{ data.duration }}س</span></h6>
            <h6 class="mb-3"><span style="font-weight: 600; font-size: 20px;color: var(--secondary);">المكان : </span>
              <span style="font-size: 20px" v-if="data.place == 'amphie-imame-malik'">مدرج الإمام مالك</span>
              <span style="font-size: 20px" v-if="data.place == 'amphie-fatima-mernissi'">قاعة فاطمة المرنيسي</span>
              <span style="font-size: 20px" v-if="data.place == 'amphie-youssi'">مدرج اليوسي</span>
              <span style="font-size: 20px" v-if="data.place == 'amphie-fatima-fihriya'">مدرج فاطمة الفهرية</span>
              <span style="font-size: 20px" v-if="data.place == 'autre'">آخر</span>
            </h6>
            <h6 class="mb-3"><span style="font-weight: 600; font-size: 20px;color: var(--secondary);">المنسق : </span><span style="font-size: 20px"> {{ data.coordinator }}</span></h6>
            <h6 class="mb-3"><span style="font-weight: 600; font-size: 20px;color: var(--secondary);">المختبر : </span><span style="font-size: 20px"> {{ data.laboratory === 'aucun' ? 'بدون' : data.laboratory }}</span></h6>
            <h6 class="mb-3"><span style="font-weight: 600; font-size: 20px;color: var(--secondary);">الشعبة	 : </span><span style="font-size: 20px"> {{ data.department === 'aucun' ? 'بدون' : data.department }}</span></h6>
            <h6 class="mb-3"><span style="font-weight: 600; font-size: 20px;color: var(--secondary);">نوع النشاط		 : </span>
              <span style="font-size: 20px" v-if="data.type == 'scientifique'"> علمي</span>
              <span style="font-size: 20px" v-if="data.type == 'culturelle'"> ثقافي</span>
            </h6>
            <h6 class="mb-3"><span style="font-weight: 600; font-size: 20px;color: var(--secondary);"> الحالة		 : </span>
            <span style="font-size: 20px" class="encour" v-if="data.status == 'encours'">جاري</span>
            <span style="font-size: 20px" class="valider" v-if="data.status == 'valider'">مقبول</span>
            <span style="font-size: 20px" class="novalider" v-if="data.status == 'novalider'">مرفوض</span>
            </h6>
          </div>
    </div>
  </div>
</div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
const Path = "Event/";
export default {
  data() {
    return {
      masks: {
        weekdays: "WWW",
      },
      attrs: [],
      data: {},
    };
  },
  mounted() {
    this.fetchEventData();
  },
  methods: {
    ...mapActions({
      fetch: Path + "fetch",
      fetchVal : Path + "fetchVal",
      fetchOne: Path + "fetchOne",
    }),
    capitalizeFirstLetter(str) {
      return str?.charAt(0).toUpperCase() + str?.slice(1);
    },
    async OneFetch(id) {
      this.$refs.exampleModalButton.click();
      try {
        const result = await this.fetchOne(id);
        const getOneData = result.response.data.data;

        if (result.success) {
          this.data = { ...getOneData };
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchEventData() {
      try {
        const result = await this.fetch();
        const data = result.response.data.data;

        if (result.success) {
          if (Array.isArray(data)) {
            this.attrs = data.map((event) => ({
              key: event.id,
              dates: [event.date],
              customData: {
                title: event.title,
                titleModal: event.title,
                place: event.place,
                id: event.id,
                class:
                  event.place === "amphie-fatima-mernissi" &&
                  new Date(event.date) > new Date()
                    ? "bg-primary text-white"
                    : event.place === "amphie-fatima-fihriya" &&
                      new Date(event.date) > new Date()
                    ? "bg-success text-white"
                    : event.place === "amphie-imame-malik" &&
                      new Date(event.date) > new Date()
                    ? "bg-danger text-white"
                    : event.place === "amphie-youssi" &&
                      new Date(event.date) > new Date()
                    ? "bg-warning text-white"
                    : event.place === "autre" &&
                      new Date(event.date) > new Date()
                    ? "bg-info text-white"
                    : "bg-secondary text-white",
              },
              popover: {
                label: event.title,
                visibility: "click",
              },
              order: 0,
            }));
          } else {
            console.error("La réponse de l'API n'est pas au format attendu");
          }
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
      }
    },
  },
};
</script>
<style>
.modal-event .modal-header {
    color: cadetblue;
}
.modal-body {
    font-family: sans-serif;
}
.close {
  color: white;
}
.vc-container {
  width: 100% !important;
}
.color-intro {
  padding: 8px;
  color: white;
  border-radius: 5px;
}
.vc-week {
  border-top: 1px solid rgba(0, 0, 0, 0.072);
  padding: 10px 0;
  text-align: center;
}
.vc-day {
  padding: 5px;
}
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  display: none;
}

/deep/ .custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;

  border-radius: 0;
  width: 100%;

  & .vc-header {
    background-color: #f1f5f8;
    padding: 10px 0;
  }
  & .vc-weeks {
    padding: 0;
  }
  & .vc-weekday {
    background-color: var(--weekday-bg);
    border-bottom: var(--weekday-border);
    border-top: var(--weekday-border);
    padding: 5px 0;
  }
  & .vc-day {
    padding: 0 5px 3px 5px;
    text-align: left;
    height: var(--day-height);
    min-width: var(--day-width);
    background-color: white;
    &.weekday-1,
    &.weekday-7 {
      background-color: #eff8ff;
    }
    &:not(.on-bottom) {
      border-bottom: var(--day-border);
      &.weekday-1 {
        border-bottom: var(--day-border-highlight);
      }
    }
    &:not(.on-right) {
      border-right: var(--day-border);
    }
  }
  & .vc-day-dots {
    margin-bottom: 5px;
  }
}
@media (max-width: 932px) {
  .title-event {
    display: none;
  }
  .btn-success {}
}
</style>
