<template>
  <aside class="control-sidebar control-sidebar-dark">
    <div class="p-3">
      <h5></h5>
      <p></p>
    </div>
  </aside>
  <footer class="main-footer">
    <div class="float-right d-none d-sm-inline"></div>
    <strong>Droits d'auteur &copy; 2024-2025 Par <a href="#">FLSHM</a>.</strong>
    Tous droits réservés.
  </footer>
</template>
