<template>
  <div class="wrapper">
    <NavComponent />
    <AsideComponent />
    <div class="content-wrapper" @click="handleOutsideClick">
      <div class="content policear">
        <div class="container-fluid table-crud">
          <div class="container-fluid">
            <div class="table-responsive">
              <div class="table-wrapper">
                <div class="table-title">
                  <div class="row">
                    <div class="col-sm-5 text-right">
                      <h2>لائحة المستخدمين <b></b></h2>
                    </div>
                    <div class="col-sm-3 input-search">
                      <input
                        v-model="search"
                        style="padding: 16px !important; font-size: x-small"
                        type="search"
                        class="form-control border-0 bg-white"
                        id="search-input"
                        placeholder="البحث"
                      />
                    </div>
                    <div class="col-sm-4">
                      <a
                        href="#addEmployeeModal"
                        class="btn btn-success"
                        data-toggle="modal"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-plus-square ml-1"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"
                          />
                          <path
                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"
                          />
                        </svg>
                        <span>إضافة</span></a
                      >
                    </div>
                  </div>
                </div>
                <table class="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th class="text-right">الإسم و النسب</th>
                      <th class="text-right">البريد الإلكتروني</th>
                      <th class="text-right">الدور</th>
                      <th class="text-right">إجراءات</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in getData.data" :key="item.id">
                      <td class="text-right">{{ item.name?.toUpperCase() }}</td>
                      <td class="text-right">{{ item.email }}</td>
                      <td class="text-right">
                        <span
                          :class="{
                            user: item?.role == 'user',
                            admin: item?.role == 'admin',
                          }"
                          >{{ item.role === "admin" ? "مسؤول" : "مستخدم" }}</span
                        >
                      </td>
                      <td class="text-right">
                        <a
                          href="#editEmployeeModal"
                          @click="OneFetch(item.id)"
                          class="edit mr-1"
                          data-toggle="modal"
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
                            />
                            <path
                              fill-rule="evenodd"
                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                            /></svg
                        ></a>
                        <a
                          href="#deleteEmployeeModall"
                          @click="submitDestroy(item.id)"
                          class="delete"
                          data-toggle="modal"
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-trash"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"
                            />
                            <path
                              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"
                            /></svg
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="clearfix mt-5 text-right">
                  <PaginationComponent
                    :current-page="getCurrentPage"
                    :total-pages="getTotalPages"
                    @page-changed="PageChange"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterBackComponent />
  </div>

  <div id="addEmployeeModal" class="modal fade" ref="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="submit">
          <div class="modal-header" dir="rtl">
            <h4 class="modal-title policear text-right">إضافة مستخدم</h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div class="modal-body policear">
            <div class="form-group">
              <label>الإسم و النسب</label>
              <input
                type="text"
                class="form-control"
                v-model="data.name"
                @blur="v$.data.name.$touch"
                :class="{ error: v$.data.name.$errors.length }"
              />
              <div
                class="input-errors"
                v-for="error of v$.data.name.$errors"
                v-bind:key="error.$uid"
              >
                <span class="error-msg">{{ error.$message }}</span>
              </div>
            </div>
            <div class="form-group">
              <label>البريد الإلكروني</label>
              <input
                type="email"
                class="form-control"
                v-model="data.email"
                @blur="v$.data.email.$touch"
                :class="{ error: v$.data.email.$errors.length }"
              />
              <div
                class="input-errors"
                v-for="error of v$.data.email.$errors"
                v-bind:key="error.$uid"
              >
                <span class="error-msg">{{ error.$message }}</span>
              </div>
              <div
                class="input-errors"
                style="text-align: left"
                v-if="getErrorAuth"
              >
                <span class="error-msg">{{ getErrorAuth }}</span>
              </div>
            </div>
            <div class="form-group">
              <label>كلمة السر</label>
              <input
                type="password"
                class="form-control"
                v-model="data.password"
                @blur="v$.data.password.$touch"
                :class="{ error: v$.data.password.$errors.length }"
              />
              <div
                class="input-errors"
                v-for="error of v$.data.password.$errors"
                v-bind:key="error.$uid"
              >
                <span class="error-msg">{{ error.$message }}</span>
              </div>
              
            </div>
            <div class="form-group">
              <label>الدور</label>
              <select
                class="form-select"
                v-model="data.role"
                @blur="v$.data.role.$touch"
                :class="{ error: v$.data.role.$errors.length }"
              >
                <option value="admin">مسؤول</option>
                <option value="user">مستخدم</option>
              </select>
              <div
                class="input-errors"
                v-for="error of v$.data.role.$errors"
                :key="error.$uid"
              >
                <span class="error-msg">{{ error.$message }}</span>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <input
              ref="removeButton"
              type="button"
              class="btn btn-default"
              style="background: beige !important"
              data-dismiss="modal"
              value="حدف"
            />
            <input
              type="submit"
              class="btn btn-success"
              :disabled="!isAllFieldsFilled"
              value="أضف"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
  <div id="editEmployeeModal" class="modal fade" ref="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="submitUpdate">
          <div class="modal-header">
            <h4 class="modal-title policear text-right">تعديل مستخدم</h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div class="modal-body policear">
            <div class="form-group">
              <label>الإسم و النسب</label>
              <input
                type="text"
                class="form-control"
                v-model="data.name"
                @blur="v$.data.name.$touch"
                :class="{ error: v$.data.name.$errors.length }"
              />
              <div
                class="input-errors"
                v-for="error of v$.data.name.$errors"
                v-bind:key="error.$uid"
              >
                <span class="error-msg">{{ error.$message }}</span>
              </div>
            </div>

            <div class="form-group">
              <label>البريد الإلكروني</label>
              <input
                type="email"
                class="form-control"
                v-model="data.email"
                @blur="v$.data.email.$touch"
                :class="{ error: v$.data.email.$errors.length }"
              />
              <div
                class="input-errors"
                v-for="error of v$.data.email.$errors"
                v-bind:key="error.$uid"
              >
                <span class="error-msg">{{ error.$message }}</span>
              </div>
              <div
                  class="input-errors"
                  style="text-align: left"
                  v-if="getErrorAuth"
                >
                  <span class="error-msg">{{ getErrorAuth }}</span>
                </div>
            </div>
            <div class="form-group">
              <button
                type="button"
                class="btn btn-info"
                @click="changePassword = !changePassword"
              >
                {{ changePassword ? "تغيير كلمة السر" : "حدف" }}
              </button>
            </div>

            <Transition>
              <div class="form-group" v-if="!changePassword">
                <label>كلمة السر</label>
                <input
                  type="password"
                  class="form-control"
                  v-model="data.password"
                  @blur="v$.data.password.$touch"
                  :class="{ error: v$.data.password.$errors.length }"
                />
                <div
                  class="input-errors"
                  v-for="error of v$.data.password.$errors"
                  v-bind:key="error.$uid"
                >
                  <span class="error-msg">{{ error.$message }}</span>
                </div>
                
              </div>
            </Transition>
            <div class="form-group mt-3">
              <label>الدور</label>
              <select
                class="form-control"
                v-model="data.role"
                @blur="v$.data.role.$touch"
                :class="{ error: v$.data.role.$errors.length }"
              >
                <option value="admin">مسؤول</option>
                <option value="user">مستخدم</option>
              </select>
              <div
                class="input-errors"
                v-for="error of v$.data.role.$errors"
                :key="error.$uid"
              >
                <span class="error-msg">{{ error.$message }}</span>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <input
              ref="removeButtonUpdate"
              type="button"
              style="background: beige !important"
              class="btn btn-default"
              data-dismiss="modal"
              value="حدف"
            />
            <input type="submit" class="btn btn-success" value="تعديل" />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { mapGetters, mapActions } from "vuex";
const Path = "User/";
export default {
  data() {
    return {
      changePassword: true,
      search: "",
      v$: useVuelidate(),
      data: {
        id: "",
        name: "",
        email: "",
        password: "",
        role: "",
      },
      isAllFieldsFilled: false,
    };
  },
  validations() {
    return {
      data: {
        name: { required },
        email: { required, email },
        password: { required },
        role: { required },
      },
    };
  },
  computed: {
    ...mapGetters({
      getData: Path + "getData",
      getCurrentPage: Path + "getCurrentPage",
      getTotalPages: Path + "getTotalPages",
      getErrorAuth: "User/getErrorAuth",
    }),
  },
  watch: {
    search: {
      handler(newSearch) {
        const searchTerm = newSearch || "";
        this.$store.dispatch(Path + "fetch", searchTerm);
      },
      immediate: true,
    },
    data: {
      handler: "checkAllFields",
      deep: true,
    },
  },
  methods: {
    checkAllFields() {
      this.isAllFieldsFilled =
        this.data.name &&
        this.data.email &&
        this.data.password &&
        this.data.role;
    },
    ...mapActions({
      fetch: Path + "fetch",
      fetchOne: Path + "fetchOne",
      store: Path + "store",
      update: Path + "update",
      destroy: Path + "destroy",
      changePage: Path + "changePage",
    }),
    handleOutsideClick(event) {
      const modal = this.$refs.modal;
      if (modal && !modal.contains(event.target)) {
        this.clearFormData();
      }
    },
    async PageChange(newPage) {
      try {
        const result = await this.changePage(newPage);
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    },
    async OneFetch(id) {
      try {
        const result = await this.fetchOne(id);
        const getOneData = result.response.data.data;

        if (result.success) {
          this.data = { ...getOneData };
        }
      } catch (error) {
        console.log(error);
      }
    },
    async submit() {
      try {
        const result = await this.store(this.data);
        if (result.success) {
          this.fetch();
          this.$refs.removeButton.click();
          this.clearFormData();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async submitUpdate() {
      if (this.changePassword) {
        this.data.password = "";
      }
      try {
        const result = await this.update(this.data);
        if (result.success) {
          this.fetch();
          this.$refs.removeButtonUpdate.click();
          this.clearFormData();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async submitDestroy(data) {
      try {
        const result = await this.destroy(data);
        if (result.success) {
          this.fetch();
        }
      } catch (error) {
        console.log(error);
      }
    },
    clearFormData() {
      this.data = {
        id: "",
        name: "",
        email: "",
        password: "",
        role: "",
      };
      this.v$.$reset();
      this.changePassword = true;
    },
  },
  mounted() {
    this.fetch();
    document.body.classList.add("hold-transition", "sidebar-mini");
  },
  /*beforeRouteLeave(to, from, next) {
    console.log("beforeRouteLeave");
    document.body.classList.remove("hold-transition", "sidebar-mini");
    next();
  },*/
};
</script>
<style scoped>
.modal .form-group {
  text-align: right;
}
.form-control, .form-select {
  text-align: right;
}
@media (max-width: 932px) {
.btn-success {
    margin-top: 15px;
}
.input-search {
    margin-top: 20px;
}
.input-search .form-control {
  width: 250px;
}
}
</style>
