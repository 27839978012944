<template>
  <form @submit.prevent="submit">
    <div class="modal-header">
      <h4 class="modal-title">طلب السفر الى الخارج</h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-hidden="true"
      >
        &times;
      </button>
    </div>
    <div class="modal-body policear">
      <div class="form-group">
        <label>الإسم الشخصي للمستفيد</label>
        <input
          type="text"
          class="form-control"
          v-model="data.first_name_benefit"
          @blur="v$.data.first_name_benefit.$touch"
          :class="{ error: v$.data.first_name_benefit.$errors.length }"
        />
        <div
          class="input-errors"
          v-for="error of v$.data.first_name_benefit.$errors"
          :key="error.$uid"
        >
          <span class="error-msg">{{ error.$message }}</span>
        </div>
      </div>
      <div class="form-group">
        <label>الإسم العائلي للمستفيد</label>
        <input
          type="text"
          class="form-control"
          v-model="data.last_name_benefit"
          @blur="v$.data.last_name_benefit.$touch"
          :class="{ error: v$.data.last_name_benefit.$errors.length }"
        />
        <div
          class="input-errors"
          v-for="error of v$.data.last_name_benefit.$errors"
          :key="error.$uid"
        >
          <span class="error-msg">{{ error.$message }}</span>
        </div>
      </div>
      <div class="form-group">
        <label>تاريخ الذهاب</label>
        <input
          lang="en-us"
          type="date"
          class="form-control"
          v-model="data.date_go"
          @blur="v$.data.date_go.$touch"
          :class="{ error: v$.data.date_go.$errors.length }"
        />
        <div
          class="input-errors"
          v-for="error of v$.data.date_go.$errors"
          :key="error.$uid"
        >
          <span class="error-msg">{{ error.$message }}</span>
        </div>
      </div>
      <div class="form-group">
        <label>تاريخ العودة</label>
        <input
          lang="en-us"
          type="date"
          class="form-control"
          v-model="data.date_return"
          @blur="v$.data.date_return.$touch"
          :class="{ error: v$.data.date_return.$errors.length }"
        />
        <div
          class="input-errors"
          v-for="error of v$.data.date_return.$errors"
          :key="error.$uid"
        >
          <span class="error-msg">{{ error.$message }}</span>
        </div>
      </div>
      <div class="form-group">
        <label>الوجهة</label>
        <input
          type="text"
          class="form-control"
          v-model="data.destination"
          @blur="v$.data.destination.$touch"
          :class="{ error: v$.data.destination.$errors.length }"
        />
        <div
          class="input-errors"
          v-for="error of v$.data.destination.$errors"
          :key="error.$uid"
        >
          <span class="error-msg">{{ error.$message }}</span>
        </div>
      </div>
      <div class="form-group">
        <label>المختبر</label>
        <input type="text" class="form-control" v-model="data.laboratory" />
      </div>
      <div class="form-group">
        <label>الشعبة</label>
        <input type="text" class="form-control" v-model="data.department" />
      </div>
    </div>
    <div class="modal-footer">
      <input
        ref="removeButton"
        type="button"
        class="btn btn-default"
        style="background: beige !important"
        data-dismiss="modal"
        value="حذف"
      />
      <input
        type="submit"
        class="btn btn-success"
        :disabled="!isAllFieldsFilled"
        value="إرسال"
      />
    </div>
  </form>
</template>
<script>
import Swal from "sweetalert2";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapGetters, mapActions } from "vuex";
const Path = "Mobilitie/";
const PathConstrained = "Constrained/";
export default {
  data() {
    return {
      v$: useVuelidate(),
      isAllFieldsFilled: false,
      message: false,
      data: {
        id: "",
        first_name_benefit: "",
        last_name_benefit: "",
        date_go: "",
        date_return: "",
        destination: "",
        department: "",
        laboratory: "",
        status: "encours",
      },
    };
  },
  watch: {
    data: {
      handler: "checkAllFields",
      deep: true,
    },
  },
  methods: {
    checkAllFields() {
      this.isAllFieldsFilled =
        this.data.first_name_benefit &&
        this.data.last_name_benefit &&
        this.data.date_go &&
        this.data.date_return &&
        this.data.destination;
    },
    ...mapActions({
      store: Path + "store",
      fetchAuth: Path + "fetchAuth",
      fetchTwoConstrained: PathConstrained + "fetchTwo",
    }),
    async submit() {
      const currentDate = new Date();
      const selectedDate = new Date(this.data.date_go);
      const timeDifference = selectedDate.getTime() - currentDate.getTime();

      if (timeDifference < this.getTowDays * 24 * 60 * 60 * 1000) {
        this.errorMessage = `يجب الإخبار بموعد السفر ${this.getTowDays} يوما  على الأقل قبل تاريخ السفر`;
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: this.errorMessage,
        });
      } else {
        try {
        const result = await this.store(this.data);
        if (result.success) {
          this.$refs.removeButton.click();
          this.clearFormData();
          this.message = true;
          this.$emit("messageToParent", this.message);
          this.fetchAuth();
        }
      } catch (error) {
        console.log(error);
      }
      }

      
    },
    clearFormData() {
      this.data = {
        id: "",
        first_name_benefit: "",
        last_name_benefit: "",
        date_go: "",
        date_return: "",
        destination: "",
        department: "",
        laboratory: "",
        status: "encours",
      };
      this.v$.$reset();
    },
  },
  validations() {
    return {
      data: {
        first_name_benefit: { required },
        last_name_benefit: { required },
        date_go: { required },
        date_return: { required },
        destination: { required },
        department: { required },
        laboratory: { required },
      },
    };
  },
  computed: {
    imagePath() {
      return this.$store.state.imagePath;
    },
    ...mapGetters({
      getTowDays: PathConstrained + "getTowDays"
    }),
  },
  mounted() {
    this.fetchTwoConstrained();
  },
};
</script>
<style scoped>
.modal-content {
  direction: rtl;
}
.modal-header {
  direction: rtl;
}
.form-control {
  direction: rtl;
}
.form-group {
  text-align: right;
}
.form-select {
  direction: rtl;
}
</style>
