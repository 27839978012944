<template>
  <div class="wrapper">
    <NavComponent />
    <AsideComponent />
    <div class="content-wrapper" @click="handleOutsideClick">
      <div class="content policear">
        <div class="container-fluid table-crud">
          <div class="container-fluid">
            <div class="table-responsive">
              <div class="table-wrapper">
                <div class="table-title">
                  <div class="row">
                    <div class="col-sm-5 text-right">
                    <h2 class="title-table-list">عدد الأيام المطلوب إخبارنا بها قبل أي نشاط<b></b></h2>
                    </div>
                  </div>
                </div>
                <table class="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th class="text-right">عدد الأيام</th>
                      <th class="text-right">إجراءات</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-right">{{ getOneData.nbr_day }}</td>
                      <td class="text-right">
                        <a
                          href="#editEmployeeModal"
                          @click="OneFetch(getOneData.id)"
                          class="edit mr-1"
                          data-toggle="modal"
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
                            />
                            <path
                              fill-rule="evenodd"
                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                            /></svg
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>




      <div class="content policear">
        <div class="container-fluid table-crud">
          <div class="container-fluid">
            <div class="table-responsive">
              <div class="table-wrapper">
                <div class="table-title">
                  <div class="row">
                    <div class="col-sm-5 text-right">
                    <h2 class="title-table-list">عدد الأيام المطلوب إخبارنا بها قبل أي سفر<b></b></h2>
                    </div>
                  </div>
                </div>
                <table class="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th class="text-right">عدد الأيام</th>
                      <th class="text-right">إجراءات</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr> 
                      <td class="text-right">{{ getTwoData.nbr_day }}</td>
                      <td class="text-right">
                        <a
                          href="#editEmployeeModalAuth"
                          @click="TowFetch()"
                          class="edit mr-1"
                          data-toggle="modal"
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
                            />
                            <path
                              fill-rule="evenodd"
                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                            /></svg
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>



    </div>

    
    <FooterBackComponent />
  </div>

  <div id="editEmployeeModal" class="modal fade" ref="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="submitUpdate">
          <div class="modal-header" dir="rtl">
            <h4 class="modal-title policear text-right">تعديل</h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div class="modal-body policear">
            <div class="form-group">
              <label>عدد الأيام</label>
              <input
                type="text"
                class="form-control"
                v-model="data.nbr_day"
                @blur="v$.data.nbr_day.$touch"
                :class="{ error: v$.data.nbr_day.$errors.length }"
              />
              <div
                class="input-errors"
                v-for="error of v$.data.nbr_day.$errors"
                v-bind:key="error.$uid"
              >
                <span class="error-msg">{{ error.$message }}</span>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <input
              ref="removeButtonUpdate"
              type="button"
              style="background: beige !important"
              class="btn btn-default"
              data-dismiss="modal"
              value="حدف"
            />
            <input type="submit" class="btn btn-success" value="تعديل" />
          </div>
        </form>
      </div>
    </div>
    
  </div>


<div id="editEmployeeModalAuth" class="modal fade" ref="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="submitTwoUpdate">
          <div class="modal-header" dir="rtl">
            <h4 class="modal-title policear text-right">تعديل</h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div class="modal-body policear">
            <div class="form-group">
              <label>عدد الأيام</label>
              <input
                type="text"
                class="form-control"
                v-model="dataTwo.nbr_day"
                @blur="v$.dataTwo.nbr_day.$touch"
                :class="{ error: v$.dataTwo.nbr_day.$errors.length }"
              />
              <div
                class="input-errors"
                v-for="error of v$.dataTwo.nbr_day.$errors"
                v-bind:key="error.$uid"
              >
                <span class="error-msg">{{ error.$message }}</span>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <input
              ref="removeButtonUpdateTwo"
              type="button"
              style="background: beige !important"
              class="btn btn-default"
              data-dismiss="modal"
              value="حدف"
            />
            <input type="submit" class="btn btn-success" value="تعديل" />
          </div>
        </form>
      </div>
    </div>
    
  </div>

  
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapGetters, mapActions } from "vuex";
const Path = "Constrained/";
export default {
  data() {
    return {
      v$: useVuelidate(),
      data: {
        id: "",
        name: "ليسبليبل",
        nbr_day: "",
      },
      dataTwo: {
        id: "",
        name: "ليسبليبل",
        nbr_day: "",
      },
      isAllFieldsFilled: false,
    };
  },
  validations() {
    return {
      data: {
        nbr_day: { required },
      },
      dataTwo: {
        nbr_day: { required },
      },
    };
  },
  computed: {
    maVariable() {
      return this.$store.state.numberDay;
    },
    ...mapGetters({
      getOneData: Path + "getOneData",
      getTwoData: Path + "getTowData",
    }),
  },
  watch: {
    data: {
      handler: "checkAllFields",
      deep: true,
    },
  },
  methods: {
    checkAllFields() {
      this.isAllFieldsFilled =
        this.data.nbr_day
    },
    ...mapActions({
      fetchOne: Path + "fetchOne",
      fetchTwo: Path + "fetchTwo",
      update: Path + "update",
    }),
    handleOutsideClick(event) {
      const modal = this.$refs.modal;
      if (modal && !modal.contains(event.target)) {
        this.clearFormData();
      }
    },
    async OneFetch() {
      try {
        const result = await this.fetchOne();
        const getOneData = result.response.data.data;

        if (result.success) {
          this.data = { ...getOneData };
        }
      } catch (error) {
        console.log(error);
      }
    },
    async TowFetch() {
      try {
        const result = await this.fetchTwo();
        const getTwoData = result.response.data.data;

        if (result.success) {
          this.dataTwo = { ...getTwoData };
        }
      } catch (error) {
        console.log(error);
      }
    },
    async submitUpdate() {
      try {
        const result = await this.update(this.data);
        if (result.success) { 
          this.fetchOne();
          this.$refs.removeButtonUpdate.click();
          this.clearFormData();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async submitTwoUpdate() {
      try {
        const result = await this.update(this.dataTwo);
        if (result.success) { 
          this.fetchTwo();
          this.$refs.removeButtonUpdateTwo.click();
          this.clearFormData();
        }
      } catch (error) {
        console.log(error);
      }
    },
    clearFormData() {
      this.data = {
        id: "",
        nbr_day: "",
      };
      this.v$.$reset();
    },
  },
  mounted() {
    this.fetchOne();
    this.fetchTwo();
    document.body.classList.add("hold-transition", "sidebar-mini");
  },
  /*beforeRouteLeave(to, from, next) {
    console.log("beforeRouteLeave");
    document.body.classList.remove("hold-transition", "sidebar-mini");
    next();
  },*/
};
</script>
<style scoped>
.modal .form-group {
  text-align: right;
}
.form-control, .form-select {
  text-align: right;
}
@media (max-width: 932px) {
  table {
    width: 29%;
  }
  .title-table-list {
    font-size: 14px !important;
  }
  }
</style>
