import axios from "axios";
import router from "@/router";
import Swal from "sweetalert2";

//const API_BASE_URL = "/auth";

export default {
  namespaced: true,
  state: {
    token: null,
    roleAuth: "",
    emailExists: "",
    emailNotExists: "",
    errorAuth: "",
    errorResetPassword: "",
    auth: {},
    isLoading: false,
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setRoleAuth(state, roleAuth) {
      state.roleAuth = roleAuth;
    },
    setEmailExists(state, data) {
      state.emailExists = data;
    },
    setEmailNotExists(state, data) {
      state.emailNotExists = data;
    },
    setErrorResetPassword(state, data) {
      state.errorResetPassword = data;
    },
    setErrorAuth(state, data) {
      state.errorAuth = data;
    },
    setAuth(state, data) {
      state.auth = data;
    },
    setLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
  getters: {
    authenticated(state) {
      return state.token;
    },
    getRoleAuth(state) {
      return state.roleAuth;
    },
    getEmailExists(state) {
      return state.emailExists;
    },
    getEmailNotExists(state) {
      return state.emailNotExists;
    },
    getErrorResetPassword(state) {
      return state.errorResetPassword;
    },
    getErrorAuth(state) {
      return state.errorAuth;
    },
    getAuth(state) {
      return state.auth;
    },
    isLoading: (state) => state.isLoading,
  },
  actions: {
    async signIn({ commit, dispatch }, credentials) {
      try {
        const response = await axios.post("auth/login", credentials);
        commit("setErrorAuth", "");
        router.replace({ name: "home" }); 
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "تم تسجيل الدخول بنجاح",
          showConfirmButton: false,
          timer: 1500,
        });
        //return dispatch("attempt", {token: response.data.access_token, role: response.data.roleAuth});
        return dispatch("attempt", response.data.access_token);
      } catch (error) {
        commit("setErrorAuth", error.response?.data?.error);
      }
    },
    async attempt({ commit, state }, token) {
      try {
        if (token) {
          commit("setToken", token);
        }
        if (!state.token) {
          return;
        }
        const response = await axios.post(`auth/me`);
        commit("setAuth", response.data.data);
        commit("setRoleAuth", response.data.data.role);
      } catch (error) {
        commit("setToken", null);
      }
    },
    async signUp({ commit }, credentials) {
      try {
        await axios.post("auth/store", credentials).then(() => {
          document.getElementById("signin-modal").click();
          commit("setEmailExists", "");
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "تمت إضافة الحساب.",
            showConfirmButton: false,
            timer: 1500,
          });
        });
      } catch (error) {
        commit("setEmailExists", error.response.data.emailExists);
      }
    },
    async signOut({ commit }) {
      try {
        await axios.post("auth/logout").then(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("roleAuth");
          commit("setToken", null);
          commit("setRoleAuth", null);
          router.replace({ name: "login" });
        });
      } catch (error) {
        // Handle error
      }
    },
    async forgotPassword({ commit }, params) {
      try {
        await axios.post("auth/forgot-password", params).then(() => {
          document.getElementById("signin-modal").click();
          commit("setEmailNotExists", "");
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "تفقد بريدك الالكتروني من فضلك",
            showConfirmButton: false,
            timer: 2000,
          });
        });
      } catch (error) {
        commit("setEmailNotExists", error.response.data.emailNotExists);
      }
    },
    async ResetPassword({ commit }, params) {
      try {
        await axios.post("auth/reset-password", params).then(() => {
          commit("setErrorResetPassword", "");
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "تم تغيير كلمة السر",
            showConfirmButton: false,
            timer: 2000,
          });
          router.replace({ name: "home" });
        });
      } catch (error) {
        commit("setErrorResetPassword", error.response.data.error);
      }
    },
  },
};
