import { createStore } from 'vuex'
import AuthModule from "./Auth";
import UserModule from "./User";
import EventModule from "./Event";
import ConstrainedModule from "./Constrained";
import MobilitieModule from "./Mobilitie";

export default createStore({
  state: {
    imagePath: 'https://events.recherche-scientifique-flshm.ma/backevent/storage/app/public/images/',
    //imagePath: 'http://127.0.0.1:8000/storage/images/',
    numberDay: 30
  },
  getters: {
    getNumberDay(state, numberDay) {
      state.numberDay = numberDay;
    }
  },
  mutations: {
    setNumberDay(state, numberDay) {
      state.numberDay = numberDay;
    }
  },
  actions: {
  },
  modules: {
    Auth: AuthModule,
    User: UserModule,
    Event: EventModule,
    Constrained: ConstrainedModule,
    Mobilitie: MobilitieModule
  }
})
