import axios from "axios";
import Swal from 'sweetalert2';

const API_BASE_URL = "/constraineds";
const MESSAGES_SUCCES = {
  creerUtilisateur: "تم إنشاؤه بنجاح",
  miseAJour: "تم التحديث",
};

export default {
  namespaced: true,
  state: {
    dataOne: {},
    dataTwo: {},
    daysTwo: '',
    isLoading: false,
  },
  mutations: {
    setOneData(state, dataOne) {
      state.dataOne = dataOne;
    },
    setTowData(state, dataTwo) {
      state.dataTwo = dataTwo;
    },
    setDays(state, days) {
      state.days = days;
    },
    setTowDays(state, daysTwo) {
      state.daysTwo = daysTwo;
    },
    setLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
  getters: {
    getOneData: (state) => state.dataOne,
    getTowData: (state) => state.dataTwo,
    getDays: (state) => state.days,
    getTowDays: (state) => state.daysTwo,
    isLoading: (state) => state.isLoading,
  },
  actions: {
    async fetchOne({ commit }) {
      commit("setLoading", true);
      try {
        const response = await axios.get(`${API_BASE_URL}/1`);
        const item = response.data.data;
        commit("setOneData", item);
        commit("setDays", item.nbr_day);
        return { success: true, response };
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        commit("setLoading", false); // Fin du chargement
      }
    },
    async fetchTwo({ commit }) {
      commit("setLoading", true);
      try {
        const response = await axios.get(`${API_BASE_URL}/2`);
        const item = response.data.data;
        commit("setTowData", item);
        commit("setTowDays", item.nbr_day);
        return { success: true, response };
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        commit("setLoading", false); // Fin du chargement
      }
    },
    async update({ dispatch }, params) {
      try {
        console.log(params);
        const response = await axios.put(
          `${API_BASE_URL}/${params.id}`,
          params
        );
        dispatch("showSuccessMessage", MESSAGES_SUCCES.miseAJour);
        return { success: true, response };
      } catch (error) {
        console.error(error);
      }
    },
    showSuccessMessage(_, message) {
        console.log(message);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: message,
          showConfirmButton: false,
          timer: 1500,
        });
      },
  },
  modules: {},
};
