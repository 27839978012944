<template>
  <CoverHeader />
  <div class="container">
    <nav dir="rtl" class="navbar navbar-expand-lg navbar-light pl-4 pr-4">
      <a class="navbar-brand policefr" href="#">
      {{ getAuth.name }}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style="color: green"
          width="20"
          height="20"
          fill="currentColor"
          class="bi bi-person-badge-fill"
          viewBox="0 0 16 16"
        >
          <path
            d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm4.5 0a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1zM8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6m5 2.755C12.146 12.825 10.623 12 8 12s-4.146.826-5 1.755V14a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1z"
          />
        </svg>
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item active">
            <a class="nav-link policear" href="#demandes"
              >طلب جديد <span class="sr-only">(current)</span></a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link policear" href="#historique_activite">الأنشطة</a>
          </li>
          <li class="nav-item">
            <a class="nav-link policear" href="#historique_mobility">الحركية</a>
          </li>
          <li v-if="getRoleAuth == 'admin'" class="nav-item">
            <router-link :to="{ name: 'dashboard' }" class="nav-link policear">لوحة القيادة</router-link>
          </li>
          <li class="nav-item">
            <a class="nav-link policear" @click="signOut" type="button"
              >تسجيل الخروج</a
            >
          </li>
        </ul>
      </div>
    </nav>
  </div>
  <div class="card-category container p-5" id="demandes">
    <div
      v-if="showAlert"
      dir="rtl"
      class="alert alert-success policear"
      role="alert"
    >
      تم تسجيل طلبك بنجاح وسيتم الإتصال بكم من قبل الإدارة للرد على طلبكم.
    </div>
    <!--<h2 class="title">
      <span class="policear">أطلب</span>
    </h2>-->

    <div class="row">
      <div
        class="col-12 col-md-6 col-lg-4"
        v-for="(card, index) in cards"
        :key="card.id"
        :class="getOrderClass(index)"
      >
        <div class="card">
          <a :href="card.href" data-toggle="modal">
            <img
            style="width: 199px;
    height: 166px;"
              :src="imagePath + card.imagePath"
              class="card-img-top"
              alt="img"
            />
          </a>
          <div class="card-body">
            <h5 style="color: darkblue" class="card-title policear">
              {{ card.title }}
            </h5>
            <h5 style="color: darkblue" class="card-title policefr">
              {{ card.subtitle }}
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="historique container" >
    <h2 class="title" id="historique_activite">
      <span class="policear">طلبات الأنشطة العلمية والثقافية</span>
    </h2>
    <TableActivite />
    <h2 class="title" id="historique_mobility">
      <span class="policear">طلبات السفر للخارج</span>
    </h2>
    <TableMobilite />

    <h2 class="title" id="historique_mobility">
      <span class="policear"> الأنشطة المبرمجة </span>
    </h2>
    <CalendrierApp />
  </div>
  <Footer />
  <div id="addModalScien" class="modal fade" ref="modal">
    <div class="modal-dialog">
      <div class="modal-content policear">
        <FormEvent @messageToParent="handleMessageFromChild" />
      </div>
    </div>
  </div>
  <div id="addModalCultu" class="modal fade" ref="modal">
    <div class="modal-dialog">
      <div class="modal-content policear">
        <FormCultu @messageToParent="handleMessageFromChild" />
      </div>
    </div>
  </div>
  <div id="addModalMobilite" class="modal fade" ref="modal">
    <div class="modal-dialog">
      <div class="modal-content policear">
        <FormMobilite @messageToParent="handleMessageFromChild" />
      </div>
    </div>
  </div>
  <FooterHome />
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      cards: [
        {
          id: 1,
          imagePath: "clipboard.png",
          title: "طلب تنظيم نشاط علمي",
          subtitle: "ACTIVITÉ SCIENTIFIQUE",
          href: "#addModalScien",
        },
        {
          id: 2,
          imagePath: "school.png",
          subtitle: "ACTIVITÉ CULTURELLE",
          title: "طلب تنظيم نشاط ثقافي",
          href: "#addModalCultu",
        },
        {
          id: 3,
          imagePath: "driving.png",
          title: "الحركية",
          subtitle: "MOBILITÉ",
          href: "#addModalMobilite",
        },
      ],
      showAlert: false,
    };
  },
  methods: {
    ...mapActions({
      signOut: "Auth/signOut",
    }),
    getOrderClass(index) {
      const orderClasses = ["order-3", "order-2", "order-1"];
      return orderClasses[index] || "";
    },
    handleMessageFromChild(message) {
      if (message == true) {
        this.showAlert = true;
        setTimeout(() => {
          this.showAlert = false; 
        }, 20000);
      }
    },
  },
  computed: {
    imagePath() {
      return this.$store.state.imagePath;
    },
    ...mapGetters({
      authenticated: "Auth/authenticated",
      getAuth: "Auth/getAuth",
      getRoleAuth: "Auth/getRoleAuth"
    }),
  },
  mounted() {
    this.handleMessageFromChild();
  },
};
</script>
<style scoped>
.navbar {
  background: none;
  box-shadow: 0 4px 2px -2px gray;
}
.nav-link {
  margin-left: 35px;
}
.card {
  border: none;
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: 0.5s;
  width: 100%;
  text-align: center;
}
.card a {
  padding: 30px;
}
.card-title {
  font-size: 22px;
}
.card-body {
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.card img {
  width: 50%;
}
.card:hover {
  scale: 1.1;
  z-index: 1000;
}
.title {
  text-align: center;
  margin: 5px 0 50px 0;
  font-family: "Cambria W02 Regular";
  font-weight: 700;
  color: #258eca;
  font-size: 50px;
  /*border-bottom: 3px solid #258dcb;*/
  padding-bottom: 13px;
}
@media (min-width: 1200px) {
  /* Styles pour les écrans larges */
}
@media (min-width: 768px) and (max-width: 1199px) {
  /* Styles pour les tablettes */
}
@media (max-width: 932px) {
  .navbar-toggler {
    border: none;
  }
  .navbar-brand {
    font-size: 14px;
  }
  .navbar-brand .bi-person-badge-fill {
    width: 20px;
    height: 20px;
  }
  .card {
    margin-bottom: 30px;
  }
  .card-title {
    font-size: 18px;
  }
  .card-body {
    height: 104px;
  }
  .card:hover {
    scale: 1.1;
    z-index: 1000;
  }
  .title {
    margin: 0;
    font-size: 21px;
  }
}
</style>
