import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import HomeView from "../views/FrontOffice/HomeView.vue";
import UserView from "../views/BackOffice/UserView.vue";
import LoginView from "../views/FrontOffice/LoginView.vue";
//import DashboardView from '../views/BackOffice/DashboardView.vue'
import EventView from "../views/BackOffice/EventView.vue";
import CalendrierView from "../views/BackOffice/CalendrierView.vue";
import MobiliteView from "../views/BackOffice/MobiliteView.vue";
import ConstrainedView from "../views/BackOffice/ConstrainedView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    beforeEnter: (to, from, next) => {
      if (!store.getters["Auth/authenticated"]) {
        return next({ name: "login" });
      }
      next();
    },
  },
  {
    path: "/dashboard/calendrier",
    name: "calendrier",
    component: CalendrierView,
    beforeEnter: (to, from, next) => {
      if (!store.getters["Auth/authenticated"]) {
        return next({ name: "login" });
      }
      if (store.getters["Auth/getRoleAuth"] !== "admin") {
        return next({ name: "home" });
      }
      next();
    },
  },
  {
    path: "/dashboard/nbr_jour",
    name: "constrained",
    component: ConstrainedView,
    beforeEnter: (to, from, next) => {
      if (!store.getters["Auth/authenticated"]) {
        return next({ name: "login" });
      }
      if (store.getters["Auth/getRoleAuth"] !== "admin") {
        return next({ name: "home" });
      }
      next();
    },
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/dashboard/mobilite",
    name: "mobilite",
    component: MobiliteView,
    beforeEnter: (to, from, next) => {
      if (!store.getters["Auth/authenticated"]) {
        return next({ name: "login" });
      }
      if (store.getters["Auth/getRoleAuth"] !== "admin") {
        return next({ name: "home" });
      }
      next();
    },
  },
  {
    path: "/dashboard/utilisateurs",
    name: "utilisateurs",
    component: UserView,
    beforeEnter: (to, from, next) => {
      if (!store.getters["Auth/authenticated"]) {
        return next({ name: "login" });
      }
      if (store.getters["Auth/getRoleAuth"] !== "admin") {
        return next({ name: "home" });
      }
      next();
    },
  },
  {
    path: "/dashboard/evenement",
    name: "event",
    component: EventView,
    beforeEnter: (to, from, next) => {
      if (!store.getters["Auth/authenticated"]) {
        return next({ name: "login" });
      }
      if (store.getters["Auth/getRoleAuth"] !== "admin") {
        return next({ name: "home" });
      }
      next();
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: EventView,
    beforeEnter: (to, from, next) => {
      if (!store.getters["Auth/authenticated"]) {
        return next({ name: "login" });
      }
      if (store.getters["Auth/getRoleAuth"] !== "admin") {
        return next({ name: "home" });
      }
      next();
    },
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'home' }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
