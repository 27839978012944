import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";

import VCalendar from "v-calendar";
import "v-calendar/style.css";

require("@/store/subscriber");
//axios.defaults.baseURL = "http://localhost:8000/api";
axios.defaults.baseURL = "https://events.recherche-scientifique-flshm.ma/api";

// import Back
import AsideComponent from "./components/BackOffice/AsideComponent.vue";
import NavComponent from "./components/BackOffice/NavComponent.vue";
import FooterBackComponent from "./components/BackOffice/FooterBackComponent.vue";
import PaginationComponent from "./components/BackOffice/PaginationComponent.vue";

import CoverHeader from "./components/FrontOffice/CoverHeader.vue";
import CalendrierApp from "./components/FrontOffice/CalendrierApp.vue";
import FormEvent from "./components/FrontOffice/FormEvent.vue";
import FormCultu from "./components/FrontOffice/FormCultu.vue";
import FormMobilite from "./components/FrontOffice/FormMobilite.vue";
import TableActivite from "./components/FrontOffice/TableActivite.vue";
import TableMobilite from "./components/FrontOffice/TableMobilite.vue";
import FooterHome from "./components/FrontOffice/FooterHome.vue";
import JsonExcel from "vue-json-excel3";

//createApp(App).use(store).use(router).mount('#app')

store.dispatch("Auth/attempt", localStorage.getItem("token")).then(() => {
  createApp(App)
    .use(store)
    .use(router)
    .use(VCalendar, {})
    .component("AsideComponent", AsideComponent)
    .component("CalendrierApp", CalendrierApp)
    .component("downloadExcel", JsonExcel)
    .component("FormEvent", FormEvent)
    .component("FooterHome", FooterHome)
    .component("FormCultu", FormCultu)
    .component("FormMobilite", FormMobilite)
    .component("TableActivite", TableActivite)
    .component("TableMobilite", TableMobilite)
    .component("CoverHeader", CoverHeader)
    .component("NavComponent", NavComponent)
    .component("FooterBackComponent", FooterBackComponent)
    .component("PaginationComponent", PaginationComponent)
    .mount("#app");
});
